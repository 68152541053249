import { createApp, ref, onMounted } from 'vue'

export default function headapp() {
  const menu = ref(false)
  const hover = ref(false)
  const accArg = []
  const accEventTrg = document.querySelectorAll('#headapp .acc-event')
  accEventTrg.forEach((el, index) => {
    accArg[index] = ref(false)
  })

  let timeoutId = null

  const accHoverEvent = (numb, bool) => {
    if(window.innerWidth > 1119){
      if (bool) {
        hover.value = bool
        if (timeoutId) {
          clearTimeout(timeoutId)
          timeoutId = null
        }
      } else {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
          hover.value = bool
          timeoutId = null
        }, 200)
      }
      accArg[numb].value = bool
    }
  }

  const accClickEvent = (numb) => {
    if(window.innerWidth < 1120){
      accArg[numb].value = !accArg[numb].value
    }
  }

  const app = createApp({
    setup() {
      return {
        menu,
        hover,
        accArg,
        accHoverEvent,
        accClickEvent
      }
    }
  })

  app.mount('#headapp')
}
