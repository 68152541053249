import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])
export default function index(){
  const indexSwiper = document.getElementById('indexSwiper')
  if(indexSwiper){
    const swiperParams = {
      slidesPerView: 3,
      spaceBetween: 32,
      loop: true,
      grabCursor: true,
      navigation: {
        nextEl: '.business__swipernext',
      },
    }
    const swiper = new Swiper(indexSwiper, swiperParams)
  }

}