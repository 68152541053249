import * as Rellax from 'rellax'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'

gsap.registerPlugin(ScrollTrigger);

export default function common() {
  if(document.getElementsByClassName('rellax').length > 0){
    const rellax = new Rellax('.rellax',{
      center:true
    });
  }

  if(document.getElementsByClassName('rellax-no').length > 0){
    const rellax_no = new Rellax('.rellax-no',{
      center:false
    })
  }

  const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
    header: '#site-header',
    offset: 0,
    speed: 1000,
    easing: 'easeInOutQuart',
    speedAsDuration: true,
    durationMin: 1000
  })


  const siteHeader = document.getElementById('headapp')
  if(siteHeader){
    ScrollTrigger.create({
      trigger: '#app',
      start: "top top-=100%",
      end: "bottom top",
      scrub: 1,
      once: false,
      invalidateOnRefresh: true,
      //  markers: true,
      toggleClass: { targets: siteHeader, className: "active" },
    })
  }
  
  gsap.utils.toArray('.toAnimation').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top center+=45%",
      end: "bottom center",
      toggleClass: { targets: el, className: "active" },
      once: true,
      //markers: true,
    });
  })

  gsap.utils.toArray('.toAnimationAll').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top center+=45%",
      end: "bottom center",
      toggleClass: { targets: el, className: "active" },
      once: true,
      //markers: true,
    });
  })

  setViewport()
  window.addEventListener('resize', setViewport)

  let follower = document.getElementById('follower')
	let followItem = document.querySelectorAll(".clickable")
	if(follower){
		let
			fWidth = 80,
			delay = 8,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay
				posY += (mouseY - posY) / delay
				TweenMax.set(follower, {
					css: {
						left: posX - (fWidth / 2),
						top: posY - (fWidth / 2)
					}
				});
			}
		});
		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX
			mouseY = e.clientY
		});

		for (let i = 0; i < followItem.length; i++) {
			followItem[i].removeEventListener("mouseenter", {el: follower, handleEvent: mouseEnter})
			followItem[i].removeEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
			followItem[i].addEventListener("mouseenter",{el: follower, handleEvent: mouseEnter})
			followItem[i].addEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
		}
	}
}

function setViewport(){
  const vw = window.innerWidth;
  const vh = window.innerHeight * 0.01
  const head = document.getElementById('site-header')
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw', `${vw}px`)
  if(vw < 769){
    document.documentElement.style.setProperty('--size', '10px')
  }
  else if(vw < 1200){
    document.documentElement.style.setProperty('--size', '16px')
  }
  else{
    document.documentElement.style.setProperty('--size', '32px')
  }
  if(head){
    const headSize = head.getBoundingClientRect().height
    document.documentElement.style.setProperty('--head', `${headSize}px`)
  }
}

function mouseEnter(){
	this.el.classList.add('active');
}
function mouseLeave(){
	this.el.classList.remove('active');
}