import Swiper from 'swiper/bundle'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'

gsap.registerPlugin(ScrollTrigger);
export default function estate(){

  // Swiper
  const detailSwiper = document.getElementById('detailSwiper')
  const thumbSwiper = document.getElementById('thumbSwiper')

  
  if(detailSwiper){
    const thumbSwiperParams = {
      spaceBetween: 10,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesProgress: true,
    }
    const thumbs = new Swiper(thumbSwiper, thumbSwiperParams)


    const swiperParams = {
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      loop: true,
      thumbs: {
        swiper: thumbs,
      },
    }
    const swiper = new Swiper(detailSwiper, swiperParams)
  }

}
