import * as imagesLoaded from 'imagesloaded'

import common from './common'
import index from './index'
import estate from './estate'
import headapp from './header'

document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('app'), ()=>{
    index()
    estate()
    common()
    headapp()
  })
});


